import { createRouter, createWebHistory } from 'vue-router'
// import Room from './Room.vue';
//import PageNotFound from '@/views/page-not-found';

const roomProps = r => ({ roomid: r.params.roomid });
const roomAdminProps = r => ({ roomid: r.params.roomid, adminidprop: r.params.adminidprop });
const roomActionProps = r => ({ roomid: r.params.roomid, adminid: r.params.adminidprop, action: r.params.actionname });

const navView = () => import(/* webpackChunkName: "bundle.room" */ './views/NavView.vue');
const homeView = () => import(/* webpackChunkName: "bundle.room" */ './views/Home.vue');
const roomView = () => import(/* webpackChunkName: "bundle.room" */ './views/Room.vue');
const roomObsView = () => import(/* webpackChunkName: "bundle.room" */ './views/RoomObs.vue');
const roomActionView = () => import(/* webpackChunkName: "bundle.room" */ './views/RoomAction.vue');
const loginView = () => import(/* webpackChunkName: "bundle.room" */ './views/Login.vue');
const accountView = () => import(/* webpackChunkName: "bundle.room" */ './views/Account.vue');
const checkoutView = () => import(/* webpackChunkName: "bundle.room" */ './views/Checkout.vue');
const checkoutSuccessView = () => import(/* webpackChunkName: "bundle.room" */ './views/CheckoutSuccess.vue');
const checkoutCancelView = () => import(/* webpackChunkName: "bundle.room" */ './views/CheckoutCancel.vue');

const roomToolsComp = () => import(/* webpackChunkName: "bundle.room" */ './components/RoomToolsCard.vue');
const lookSusComp = () => import(/* webpackChunkName: "bundle.room" */ './components/LooksSusCard.vue');
const questionRecorderComp = () => import(/* webpackChunkName: "bundle.room" */ './components/QuestionRecorder.vue');

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: navView,
      children: [
        {
          path: '/' ,
          component: homeView,
        } ,
      ]  
    },
    {
      path: '/login',
      name: 'login',
      component: loginView,
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: checkoutView,
    },
    { 
      path: '/checkout/room/:roomid',
      name: 'checkoutroomid',
      props: roomProps,
      component: checkoutView,
    },
    { 
      path: '/checkout/room/:roomid/:adminid',
      name: 'checkoutroomidadminid',
      props: roomProps,
      component: checkoutView,
    },
    {
      path: '/account',
      name: 'account',
      component: accountView,
    },
    {
      path: '/checkout/success',
      name: 'checkoutsuccess',
      component: checkoutSuccessView,
    },
    {
      path: '/checkout/cancel',
      name: 'checkoutcancel',
      component: checkoutCancelView,
    },
    {
      path: '/room',
      name: 'room',
      props: roomProps,
      component: roomView,
    },
    {
      path: '/room/:roomid',
      name: 'roomid',
      props: roomAdminProps,
      component: roomView,
      children: [
        { 
          path: 'admin', 
          name: 'roomadmin', 
          components: {
            roomtools: roomToolsComp,
            roomlookssus: lookSusComp,
            roomquestionrecorder: questionRecorderComp
          } 
        },
        { 
          path: 'admin/:adminidprop', 
          name: 'roomadminid', 
          components: {
            roomtools: roomToolsComp,
            roomlookssus: lookSusComp,
            roomquestionrecorder: questionRecorderComp
          } 
        },
      ]
    },
    {
      path: '/room/:roomid/obs',
      name: 'roomobs',
      props: roomProps,
      component: roomObsView,
    },
    {
      path: '/room/:roomid/admin/:adminidprop/action/:actionname',
      name: 'roomaction',
      props: roomActionProps,
      component: roomActionView,
    },
  ],
});

export default router;
