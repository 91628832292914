
<template>
  <v-app fluid>
    <router-view />
  </v-app>
</template>

<script>

  import { Hub } from 'aws-amplify/utils';
  import axios from 'axios';
  import { fetchAuthSession } from 'aws-amplify/auth';
  
  export default {
    name: 'App',
    components: { 
    },
    computed: {
      authenticated: function() {
        return this.$store.state.authorized;
      },
      currentRouteName() {
        return this.$route.name;
      },
    },
    methods: {
      signinpage: function () {
        this.$router.push('/login');
      },
      checkoutpage: function () {
        this.$router.push('/checkout');
      },
      accountpage: function () {
        if (this.currentRouteName !== 'account') {
          this.$router.push('/account')
        }
      },
      loginbackend: async function () {
        try {
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const roomurl = `${process.env.VUE_APP_NAMECHECK_URL}/user/login`;
          const obj = {
              headers: { 
                  'Authorization': `${ idToken }`
              }
          };
          await axios.get(roomurl, obj);
        } catch (err) {
          console.log(err);
        }
      },
    },
    mounted: function() {
      console.log("HUB LISTENER CREATED");

      if (!this.$store.state.authlistener) {
        Hub.listen('auth', async (data) => {
          
          console.log("data.payload.event: " + data.payload.event);

          switch (data.payload.event) {
            case 'signedIn':
              await this.$store.commit('userCheck');
              if (this.$route.path !== this.$store.state.loginredirect) {
                this.$router.push(this.$store.state.loginredirect);
              }
              await this.loginbackend();
              console.log('user signed in');
              break;
            case 'signedOut':
              console.log('user signed out');
              if (this.$route.path !== this.$store.state.loginredirect) {
                this.$router.push(this.$store.state.loginredirect);
              }
              break;
            case 'tokenRefresh':
              console.log('auth tokens have been refreshed.');
              break;
            case 'tokenRefresh_failure':
              console.log('failure while refreshing auth tokens.');
              break;
            case 'signInWithRedirect':
              console.log('signInWithRedirect API has successfully been resolved.');
              break;
            case 'signInWithRedirect_failure':
              console.log('failure while trying to resolve signInWithRedirect API.');
              break;
            case 'customOAuthState':
              logger.info('custom state returned from CognitoHosted UI');
              break;
          }
        });
        this.$store.commit('setAuthListenerFlag');
      }

    },

  };
 
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
