// import Vue from 'vue'
import { createStore } from 'vuex'
import { getCurrentUser } from 'aws-amplify/auth';
/*
  import axios from 'axios';
  import { authHelper } from '../lib/authHelper.js';
*/

// Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  authorized: false,
  authlistener: false,
  loginredirect: '/',
  alert: '',
  connectiontimercount: 0,
  checkout: {
    roomname: '',
    roomnamevalid: false,
    previewvalid: false,
    roomid: null,
    product: null,
    name: null,
    phone: null,
    email: null,
    account: null,
    adminid: null
  }
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  user: function (state, userinfo) {
    state.userinfo = userinfo;
    if (!!userinfo && userinfo.email) { 
      state.authorized = true;
    } else {
      state.authorized = false;
    }
  },
  userCheck: async function (state) {
    try {
      // await Auth.currentAuthenticatedUser()
      const { username, userId, signInDetails } = await getCurrentUser();
      state.authorized = true;
    } catch (err) {
      console.log(`userCheck: ${err}`);
      state.authorized = false;
    }
  },
  logout: function (state) {
    state.authorized = false,
    state.alert = [ { msg: "Logout Successful", type: "info", show: true } ];
  },
  clearAlert: function (state) {
    state.alert = [ ];
  },
  changeAlert: function (state, value) {
    state.alert = value;
  },
  changeLoginRedirect: function (state, value) {
    state.loginredirect = value;
  },
  setAuthListenerFlag: function (state) {
    state.authlistener = true;
  },
  setConnectionTimerCount: function (state, value) {
     state.connectiontimercount= value;
  },
  checkoutClear: function (state) {
    state.checkout.roomname = '';
    state.checkout.roomnamevalid = false;
    state.checkout.previewvalid = false;
    state.checkout.roomid = null;
    state.checkout.product = null;
    state.checkout.name = null;
    state.checkout.phone = null;
    state.checkout.email = null;
    state.checkout.account = null;
    state.checkout.adminid = null;
  },
  checkoutRoomId: function (state, value) {
    state.checkout.roomid = value;
  },
  checkoutAdminId: function (state, value) {
    state.checkout.adminid = value;
  },
  checkoutProduct: function (state, value) {
    state.checkout.product = value;
  },
  checkoutRoomName: function (state, value) {
    state.checkout.roomname = value;
    state.checkout.roomnamevalid = true;
  },
  checkoutRoomNameInvalid: function (state) {
    state.checkout.roomname = "";
    state.checkout.roomnamevalid = false;
  },
  checkoutUserInfo: function (state, value) {
    state.checkout.name = value.name;
    state.checkout.phone = value.phone;
    state.checkout.email = value.email;
    state.checkout.account = value.account;
    state.checkout.adminid = value.adminid;
  },
  checkoutPreviewValid: function (state, value) {
    state.checkout.previewvalid = value;
  }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  
}

// getters are functions.
const getters = {
  getConnectionTimerCount: (state) => () => {
    return state.connectiontimercount;
    // return JSON.parse(JSON.stringify(state.connectiontimercount));
    //let timer = JSON.parse(JSON.stringify(state.roomtimer));
    //console.log("roomtimer: ", timer);
    //return timer.find(x => x.roomid === roomid);
  }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default createStore({
  state,
  getters,
  actions,
  mutations
})