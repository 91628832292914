// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

// import Vue from 'vue'
// import App from './App.vue'

import { createApp } from 'vue' 
// import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import '@aws-amplify/ui-vue';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-vue/styles.css';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import eventBus from './eventbus'
import App from './App.vue'

const authConfig = {
    Auth : {
        Cognito: {

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      
            // REQUIRED - Amazon Cognito Region
            region: process.env.VUE_APP_LOGIN_REGION,
      
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.VUE_APP_USER_POOL_ID,
      
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.VUE_APP_LOGIN_CLIENT_ID,
      
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
      
            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
                domain: process.env.VUE_APP_COOKIE_DOMAIN,
            // OPTIONAL - Cookie path
                path: '/',
            // OPTIONAL - Cookie expiration in days
                expires: Number(process.env.VUE_APP_COOKIE_EXPIRATION),
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
                sameSite: process.env.VUE_APP_COOKIE_SAME_SITE,
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                secure: process.env.NODE_ENV !== 'development',
            },
      /*
            // OPTIONAL - customized storage object
            storage: MyStorage,
      
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_PASSWORD_AUTH',
      
            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            clientMetadata: { myCustomKey: 'myCustomValue' },
      
             // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: 'your_cognito_domain',
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: 'http://localhost:3000/',
                redirectSignOut: 'http://localhost:3000/',
                responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
      */
        }
    }
};

Amplify.configure(authConfig);
    
// export const eventBus = new Vue(); // Vue 3: move to tinyemitter

// new Vue({
//     router, 
//     vuetify,
//     store,
//     render: h => h(App)
//   }).$mount('#app');
  
const vuetify = createVuetify({
    components,
    directives,
});
const app = createApp(App);

app.use(router) 
app.use(store) 
app.use(vuetify) 

app.config.globalProperties.$bus = eventBus

app.mount('#app') ;
